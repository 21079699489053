%button {
	cursor: pointer;
	background-color: transparent;
	padding: 15px 30px;
	border-radius: 100px;
	display: inline-block;
	text-align: center;
	color: $white;
	text-transform: uppercase;
	transition: background-color 0.3s ease;
	font-family: $primary-font;
	font-weight: 700;
	line-height: 1;
	@include photoshop-to-css-font(25, 200, 2);

	@include media-breakpoint-down(md) {
		display: block;
		width: 100%;
		padding: 15px;
	}

	&.btn-md {
		padding: 13px 25px 10px;
		font-size: 0.9rem;
	}

	&.btn-sm {
		padding: 10px 15px 7px;
		font-size: 0.8rem;
	}

	&.btn-full {
		width: 100%;
	}

	@each $color, $hex in $cbr-colors {
		&.btn-#{$color} {
			border: 1px solid rgba($hex, 0.6);
			color: $hex;

			&:hover {
				background-color: $hex;
				border-color: $hex;

				@if $color == "white" {
					color: $slate;
				} @else {
					color: $white;
				}
			}
		}
	}

	&:disabled,
	&[disabled="disabled"] {
		background-color: darken($white, 20) !important;
		border-color: darken($white, 20) !important;
	}

	+ .btn {
		margin-left: 1em;

		@include media-breakpoint-down(md) {
			margin: 1em 0 0;
		}
	}
}

a.btn,
button.btn,
input.gform_button,
input[type="submit"] {
	@extend %button;
}

input[type="submit"] {
	background-color: $green;
	border-color: $green;
}
