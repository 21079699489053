section.content-section.project-grid {
	figure.project-grid-item {
		text-align: center;
		position: relative;
		height: 100%;
		@extend %background-cover;

		&,
		* {
			transition: all 0.3s ease;
		}

		figcaption {
			opacity: 0;
			height: 100%;
			padding: 6vw ($grid-gutter-width * 2);
			background-color: rgba($yellow, 0.8);
			@extend %to-edges;

			@include media-breakpoint-down(md) {
				padding: 25px;
			}

			h4 {
				color: $white;
				text-transform: uppercase;
				margin: 0 0 1em;
				@include photoshop-to-css-font(80, 10, 2);

				@include media-breakpoint-down(md) {
					@include photoshop-to-css-font(60, 10, 2);
				}

				em {
					display: block;
					font-family: $primary-font;
					font-style: normal;
					line-height: 1.3;
					margin: 0 0 0.25em;
					@include photoshop-to-css-font(35, 200, 2);
				}
			}
		}

		&:hover {
			figcaption {
				opacity: 1;
			}
		}

		&.type-title {
			figcaption {
				opacity: 1;
				background-color: rgba($slate, 0.8);
			}
		}
	}

	.project-grid-item-box {
		&:nth-child(n + 4) {
			margin-top: $grid-gutter-width;
		}

		@include media-breakpoint-down(sm) {
			margin-top: $grid-gutter-width;
		}
	}
}
