section.content-section.centered-content {
	text-align: center;

	&:not(.full-width) {
		padding: ($grid-gutter-width * 4) 16vw;

		@include media-breakpoint-down(md) {
			padding: 25px 8vw;
		}
	}

	&[class*="bg-"] {
		&[class*="tan"] {
			background-color: $tan;
		}

		&[class*="white"] {
			background-color: $white;
		}
	}
}
