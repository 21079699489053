@function photoshop-letter-spacing-to-value($photoshop-value) {
	@return $photoshop-value / 1000;
}

@function photoshop-font-size-to-value($photoshop-value-in-pt, $pixel-density: 1) {
	$px: $photoshop-value-in-pt / $pixel-density;
	$rem: $px / 16;

	@return $rem;
}
