body {
	.gform_wrapper {
		form {
			.gform_body {
				.gform_fields {
					.gfield {
						label.gfield_label {
						}

						.ginput_container {
							select {
								appearance: none;
								-webkit-appearance: none;
							}

							textarea,
							select,
							input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
								border: 1px solid lighten($slate, 20);
								width: 100%;
								padding: 12px;
								line-height: 1;
								color: $slate;
								border-radius: 3px;
							}
						}
					}
				}
			}

			.gform_footer {
				text-align: center;

				.gform_button {
				  border: 1px solid rgba(62, 77, 88, 0.6);
				  color: #3e4d58;
				  background-color: transparent;
				  border-radius: 100px;
				  text-align: center;
				  text-transform: uppercase;
				  transition: background-color 0.3s ease;
				  font-family: "Open Sans", sans-serif;
				  font-weight: 700;
				  line-height: 1;
				  font-size: 0.78125rem;
				  letter-spacing: 0.2em;
				  padding-left: 2rem;
				  padding-right: 2rem;
				}

				.gform_button:hover {
				  background-color: #3e4d58;
				  border-color: #3e4d58;
				  color: #fff;
				}

			}
		}
	}
}
