@mixin set-header-position($logo-width, $link-spacing, $link-padding) {
	a.logo {
		position: absolute;
		left: 50%;
		width: $logo-width;
		display: block;
		margin-left: -($logo-width / 2);
		z-index: 2;
	}

	nav.main-menu {
		position: relative;
		z-index: 1;

		ul.menu {
			li.menu-item {
				display: inline-block;
				margin: 0 (($link-spacing - $link-padding) / 2);
			}
		}

		&.main-menu-right {
			text-align: left;
			margin-left: $logo-width / 2;
			padding-left: (($link-spacing - $link-padding) / 2) * 2;
		}

		&.main-menu-left {
			text-align: right;
			margin-right: $logo-width / 2;
			padding-right: (($link-spacing - $link-padding) / 2) * 2;
		}
	}
}
