/*** Global App Styles ***/

body {
	font-family: $primary-font;
	color: $text-color;

	figure.cbr-logo {
		svg {
			transition: transform 0.2s ease;

			path,
			rect {
				fill: $white;
				transition: fill 0.3s ease;
			}

			g#pillar {
				path {
					opacity: 0.1;
				}
			}
		}

		&:hover {
			svg {
				transform: scale(1.1);

				g#text {
					path {
						fill: $yellow;
					}
				}
			}
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $header-font;
		font-weight: normal;
		line-height: 1.1em;
	}

	p,
	ol,
	ul,
	blockquote {
	}

	.site {
		max-width: 1500px;
		margin: $grid-gutter-width auto 0;
		position: relative;
		padding: 0 $grid-gutter-width;
	}

	.featherlight {
		&.featherlight-iframe {
			.featherlight-content {
				overflow: hidden;
			}
		}
	}
}
