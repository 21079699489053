/* Mixins */

@import "mixins/header-position";

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

@mixin pointer_arrow($pos, $size, $color, $radius: 0) {
	background: $color;
	@include border-radius($radius);

	&:after {
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba($color, 0);
		border-width: $size;

		@if $pos == "right" {
			left: 100%;
			top: 50%;
			border-left-color: $color;
			margin-top: -($size);
		} @else if $pos == "left" {
			right: 100%;
			top: 50%;
			border-right-color: $color;
			margin-top: -($size);
		} @else if $pos == "bottom" {
			top: 100%;
			left: 50%;
			border-top-color: $color;
			margin-left: -($size);
		} @else {
			bottom: 100%;
			left: 50%;
			border-bottom-color: $color;
			margin-left: -($size);
		}
	}
}

@mixin pointer_arrow_border($pos, $size, $color, $border_size, $border_color, $radius: 0) {
	background: $color;
	border: 1px solid $border_color;
	@include border-radius($radius);

	&:after,
	&:before {
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba($color, 0);
		border-width: $size;
	}

	&:before {
		border-color: rgba($border_color, 0);
		border-width: $size + $border_size;
	}

	@if $pos == "right" {
		&:after,
		&:before {
			left: 100%;
			top: 50%;
		}

		&:after {
			border-left-color: $color;
			margin-top: -($size);
		}

		&:before {
			border-left-color: $border_color;
			margin-top: -($size + $border_size);
		}
	} @else if $pos == "left" {
		&:after,
		&:before {
			right: 100%;
			top: 50%;
		}

		&:after {
			border-right-color: $color;
			margin-top: -($size);
		}

		&:before {
			border-right-color: $border_color;
			margin-top: -($size + $border_size);
		}
	} @else if $pos == "bottom" {
		&:after,
		&:before {
			top: 100%;
			left: 50%;
		}

		&:after {
			border-top-color: $color;
			margin-left: -($size);
		}

		&:before {
			border-top-color: $border_color;
			margin-left: -($size + $border_size);
		}
	} @else {
		&:after,
		&:before {
			bottom: 100%;
			left: 50%;
		}

		&:after {
			border-bottom-color: $color;
			margin-left: -($size);
		}

		&:before {
			border-bottom-color: $border_color;
			margin-left: -($size + $border_size);
		}
	}
}

@mixin bullet_before($width) {
	&:before {
		content: "\2022";
		margin-right: $width;
		padding-left: $width;
		font-weight: normal;
		opacity: 0.7;
	}
}

@mixin bullet_after($width) {
	&:after {
		content: "\2022";
		margin-left: $width;
		padding-right: $width;
		font-weight: normal;
		opacity: 0.7;
	}
}

@mixin media-breakpoint($breakpoint) {
	@media (max-width: $breakpoint) {
		@content;
	}
}

@mixin photoshop-to-css-font($photoshop-font-pt, $photoshop-letter-spacing, $pixel-density: 1) {
	font-size: photoshop-font-size-to-value($photoshop-font-pt, $pixel-density) + 0rem;
	letter-spacing: photoshop-letter-spacing-to-value($photoshop-letter-spacing) + 0em;
}
