hgroup.section-title {
	margin-bottom: ($grid-gutter-width * 4) / 2;

	.title {
		text-transform: uppercase;
		@include photoshop-to-css-font(120, 10, 2);

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(100, 10, 2);
		}

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(65, 10, 2);
		}
	}

	.subtitle {
		font-family: $primary-font;
		text-transform: uppercase;
		@include photoshop-to-css-font(35, 200, 2);

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(28, 200, 2);
		}
	}
}
