/*** Footer ***/

footer.main {
	background-color: $tan;
	margin-top: $grid-gutter-width;
	padding: $grid-gutter-width * 4;

	@include media-breakpoint-down(md) {
		padding: 25px;
	}

	a.logo {
		display: block;
		width: 75px;
		margin-right: $grid-gutter-width;

		svg {
			#pillar-top,
			#pillar-bottom {
				path,
				rect {
					fill: $blue;
				}
			}

			#text {
				path {
					fill: $text-color;
				}
			}
		}
	}

	h4 {
		font-family: $primary-font;
		text-transform: uppercase;
		font-weight: bold;
		margin: 0 0 1.25em;
		@include photoshop-to-css-font(25, 200, 2);
	}

	p {
		margin: 0 0 1em;
		@include photoshop-to-css-font(29, 25, 2);

		&.contact {
			font-weight: bold;
			text-transform: uppercase;

			a {
				color: $text-color;
			}
		}
	}

	a {
		&.social-icon {
			$icon-size: 25px;
			border: 2px solid $text-color;
			border-radius: 100%;
			width: $icon-size;
			height: $icon-size;
			line-height: $icon-size;
			display: inline-block;
			text-align: center;
			color: $text-color;

			i {
				font-size: $icon-size - 12;
				vertical-align: text-top;
				line-height: 1;
				position: relative;
				top: 3px;
			}
		}

		&.accreditation-logo {
			display: inline-block;
			margin-bottom: 1.5em;

			@include media-breakpoint-down(md) {
				display: block;
				text-align: center;
			}

			img {
				max-height: 45px;
			}

			+ a.accreditation-logo {
				margin-left: $grid-gutter-width * 2;
			}
		}
	}
}

footer.copyright {
	text-align: center;
	padding: 1.25em 0;
	@include photoshop-to-css-font(19, 35, 2);
}
