section.content-section {
	margin-top: $grid-gutter-width;

	p,
	ol,
	ul {
		line-height: 1.8em;
		@include photoshop-to-css-font(29, 35, 2);

		+ a.btn {
			margin-top: $grid-gutter-width;
		}
	}
}

@import "parts/title";
@import "split-content";
@import "centered-content";
@import "callouts";
@import "project-grid";
@import "photo-divider";
@import "photo-gallery";
