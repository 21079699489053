/*** Header ***/

$menu-link-padding: 10px;
$menu-link-spacing: 55px;

$menu-breakpoint-1: 1065px;

header.main {
	position: absolute;
	//width: 100%;
	padding: 30px 0;
	left: 0;
	right: 0;
	z-index: 1000;

	@include media-breakpoint-down(md) {
		position: static;
		background-color: darken($slate, 10);
	}

	&.desktop {
		@include set-header-position(55px, $menu-link-spacing, $menu-link-padding);

		nav.main-menu {
			ul.menu {
				li.menu-item {
					ul.sub-menu {
						opacity: 0;
						visibility: hidden;
						position: absolute;
						top: 85%;
						left: 50%;
						transform: translateX(-50%);
						background-color: darken($slate, 10);
						text-align: center;
						padding: 10px 0;
						transition: all 0.4s ease;
						@include pointer_arrow("top", 8px, darken($slate, 10));

						li.menu-item {
							ul.sub-menu {
								opacity: 0;
								visibility: hidden;
								position: absolute;
								top: 0px;
								left: 100%;
								transform: translateX(0px);
								background-color: darken($slate, 10);
								text-align: center;
								padding: 10px 0;
								transition: all 0s ease;
								@include pointer_arrow("top", 8px, darken($slate, 10));
								&:after {
									display: none;
								}
							}
							&:hover {
								ul.sub-menu {
									top: 0px;
									left: 100%;
									transform: translateX(0px);
									transition: all 0s ease;
									opacity: 1;
									visibility: visible;
								}
							}
						}
					}

					&:hover {
						> ul.sub-menu {
							top: 100%;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}

		@include media-breakpoint($menu-breakpoint-1) {
			@include set-header-position(55px, ($menu-link-spacing - 20), $menu-link-padding);
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&.mobile {
		padding: 15px 30px;
		display: none;

		@include media-breakpoint-down(md) {
			display: block;
		}

		a {
			&.logo {
				width: 55px;
				display: block;
			}

			&.mobile-toggle-button {
				display: block;
				color: $white;
				text-transform: uppercase;
				padding: 10px 15px;
				transition: all 0.3s ease;
				@include photoshop-to-css-font(32, 100, 2);

				i {
					margin-right: 5px;
				}

				&.on {
					background-color: darken($slate, 15);
				}
			}
		}

		nav.main-menu {
			position: relative;
			margin-right: -15px;

			ul.menu {
				position: absolute;
				top: 90%;
				right: 0;
				opacity: 0;
				visibility: hidden;
				z-index: 10;
				background-color: darken($slate, 15);
				text-align: right;
				padding: 12px 0;
				border-radius: 3px 0 3px 3px;
				transition: all 0.3s ease;

				li.menu-item {
					a {
						padding: 8px 20px;

						&.clicked + ul.sub-menu {
							display: block;
						}
					}
				}

				ul.sub-menu {
					background-color: rgba($black, 0.15);
					display: none;
				}

				&.open {
					top: 100%;
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	nav.main-menu {
		ul.menu {
			@extend %list-reset;

			li.menu-item {
				position: relative;

				a {
					color: $white;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 0.9rem;
					display: block;
					padding: $menu-link-padding;
					@include photoshop-to-css-font(25, 200, 2);

					&:hover {
						color: $yellow;
					}
				}

				ul.sub-menu {
					@extend %list-reset;
				}
			}
		}
	}
}
