/*** Base Styles ***/

body {
    font-family: $primary-font;
    font-size: 16px;
    background-color: $background-color;
    color: $text-color;
    -webkit-font-smoothing: antialiased;

    * {
        &:focus,
        &:hover {
            outline: none;
        }
    }

    ul {
        &.no-style {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    figure {
        margin: 0;
    }

    img {
        max-width: 100%;

        &.alignleft {
            float: left;
            margin-right: 20px;
        }

        &.alignright {
            float: right;
            margin-left: 20px;
        }

        &.alignleft,
        &.alignright {
            margin-bottom: 20px;
        }
    }

    a {
        color: $blue;
        text-decoration: none;

        &.mobile-toggle-button {
            display: none;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .group {
        zoom: 1; /* For IE 6/7 (trigger hasLayout) */

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }
}

html,
body {
    height: 100%;
}
