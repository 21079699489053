section.content-section.split-content {
	article.text {
		padding: 5vw;
		padding-left: 6vw;

		@include media-breakpoint-down(md) {
			padding: 25px;
		}

		&[class*="text-bg-"] {
			&[class*="tan"] {
				background-color: $tan;
			}

			&[class*="white"] {
				background-color: $white;
			}
		}
	}

	aside {
		position: relative;
		height: 100%;

		a.video-link {
			position: absolute;
			z-index: 10;
			@extend %to-edges;
		}

		article.form-container {
			position: relative;
			z-index: 10;

			h2 {
				font-size: 1.5rem;
				text-transform: uppercase;
			}

			.gform_wrapper form .gform_footer .gform_button {
			  border: 1px solid rgba(62, 77, 88, 0.6);
			  color: #3e4d58;
			  background-color: transparent;
			  border-radius: 100px;
			  text-align: center;
			  text-transform: uppercase;
			  transition: background-color 0.3s ease;
			  font-family: "Open Sans", sans-serif;
			  font-weight: 700;
			  line-height: 1;
			  font-size: 0.78125rem;
			  letter-spacing: 0.2em;
			  padding-left: 2rem;
			  padding-right: 2rem;
			}

			.gform_wrapper form .gform_footer .gform_button:hover {
			  background-color: #3e4d58;
			  border-color: #3e4d58;
			  color: #fff;
			}

			@include media-breakpoint-down(md) {
				padding: 30px 15px;
			}

		}

		article.project-details {
			position: relative;
			z-index: 10;
			padding-left: 5vw;

			@include media-breakpoint-down(md) {
				padding: 40px 25px;
			}

			.detail {
				h3 {
					margin: 0 0 0.5em;
					font-family: $primary-font;
					text-transform: uppercase;
					@include photoshop-to-css-font(35, 200, 2);
				}

				figure.icon {
					text-align: center;

					svg {
						height: 40px;
						max-width: 100%;

						path {
							fill: $text-color;
						}
					}
				}

				ul {
					@extend %list-reset;

					li {
						font-weight: bold;
						text-transform: uppercase;
						@include photoshop-to-css-font(25, 200, 2);
					}
				}

				+ .detail {
					margin-top: 2.5em;
				}
			}
		}

		figure.background {
			position: absolute;
			z-index: 1;
			@extend %to-edges;
			@extend %background-cover;
		}

		&[class*="sidebar-overlay-"] {
			figure.background {
				&::after {
					content: "";
					position: absolute;
					z-index: 1;
					@extend %to-edges;
				}
			}

			@each $color, $hex in $cbr-colors {
				&[class*="#{$color}"] {
					figure.background {
						&::after {
							background-color: rgba($hex, 0.9);
						}
					}
				}
			}
		}

		&[class*="sidebar-type-"] {
			&[class*="image"],
			&[class*="video"] {
				@include media-breakpoint-down(md) {
					height: 33vh;
				}
			}

			&[class*="video"] {
				a {
					&::after {
						$button-width: 150px;
						$button-height: $button-width / 1.75;
						content: "\f04b";
						font-family: "FontAwesome";
						position: absolute;
						top: 50%;
						left: 50%;
						color: $white;
						font-size: $button-width / 4;
						background-color: rgba($slate, 0.75);
						width: $button-width;
						height: $button-height;
						line-height: $button-height;
						margin-top: -($button-height / 2);
						margin-left: -($button-width / 2);
						text-align: center;
						border-radius: 10px;
						transition: all 0.3s ease;
					}
				}

				&:hover {
					a {
						&::after {
							transform: scale(1.05);
							background-color: $slate;
						}
					}
				}
			}
		}
	}
}
