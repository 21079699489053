%list-reset {
	list-style: none;
	margin: 0;
	padding: 0;
}

%to-edges {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

%background-cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
