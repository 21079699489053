section.hero {
	background-color: $slate;
	position: relative;
	min-height: 98px;

	figcaption {
		text-align: center;
		color: $white;
		position: relative;
		z-index: 3;
		padding: 0 10vw;
		background-color: rgba(saturate($slate, 15), 0.95);

		hgroup {
			padding: 0 0 4vh;
			background-image: url("assets/dist/images/leaf-watermark.png");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;

			@include media-breakpoint-down(md) {
				padding-bottom: 3vh;
			}

			h1 {
				font-family: $opensans;
				text-transform: uppercase;
				@include photoshop-to-css-font(35, 200, 2);

				@include media-breakpoint-down(md) {
					@include photoshop-to-css-font(30, 200, 2);
				}
			}

			h2 {
				margin: 0;
				text-transform: uppercase;
				line-height: 1.1em;
				@include photoshop-to-css-font(151, 10, 2);

				@include media-breakpoint-down(lg) {
					@include photoshop-to-css-font(115, 10, 2);
				}

				@include media-breakpoint-down(md) {
					@include photoshop-to-css-font(95, 10, 2);
				}

				@include media-breakpoint-down(sm) {
					@include photoshop-to-css-font(65, 10, 2);
				}
			}
		}
	}

	figure {
		position: absolute;
		z-index: 1;
		@extend %background-cover;
		@extend %to-edges;
	}

	video {
		width: 100%;
		position: relative;
		z-index: 2;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&.page {
		figcaption {
			padding: 16vh 0 10vh;

			@include media-breakpoint-down(md) {
				padding: 10vh 0;
			}
		}
	}

	&.home {
		min-height: 80vh;
		min-height: 500px;
		max-height: 700px;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			min-height: 400px;
		}

		figcaption {
			position: absolute;
			@extend %to-edges;

			article {
				margin-top: 2vh;
			}
		}
	}
}
