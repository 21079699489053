/*** Fonts ***/
@import url("https://fonts.googleapis.com/css?family=EB+Garamond|Open+Sans:300,400,600");
$opensans: "Open Sans", sans-serif;
$garamond: "EB Garamond", sans-serif;
$primary-font: $opensans;
$header-font: $garamond;

/*** Colors ***/
$white: #fff;
$black: #000;
$slate: #3e4d58;
$yellow: #e9b765;
$blue: #3c637f;
$tan: #e1e1df;
$background-color: $white;
$primary-color: $black;
$secondary-color: lighten($black, 10);
$text-color: $slate;
$cbr-colors: (
	"white": $white,
	"slate": $slate,
	"blue": $blue,
	"yellow": $yellow,
	"tan": $tan
);

/*** Template Dimensions ***/
$grid-gutter-width: 1vw;

/*** Resetting Bootstrap Breakpoints ***/
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);
