section.content-section.callouts {
	section.callout {
		background-color: $tan;
		text-align: center;
		padding: 3vw 2.5vw;
		position: relative;

		@include media-breakpoint-down(md) {
			padding: 25px;
		}

		article {
			position: relative;
			z-index: 2;

			hgroup {
				figure.icon {
					svg {
						height: 50px;

						path {
							fill: $slate;
						}
					}
				}

				h3 {
					margin: 2em 0;
					text-transform: uppercase;
					font-family: $primary-font;
					line-height: 1.8em;
					@include photoshop-to-css-font(35, 200, 2);

					em {
						font-family: $header-font;
						font-style: normal;
						display: block;
						line-height: 1em;
						@include photoshop-to-css-font(52, 10, 2);
					}
				}
			}
		}

		figure.background {
			position: absolute;
			z-index: 1;
			opacity: 0.1;
			@extend %to-edges;
		}

		&.callout-dark {
			color: $white;
			background-color: lighten($slate, 30);

			article {
				hgroup {
					figure.icon {
						svg {
							path {
								fill: $white;
							}
						}
					}
				}
			}
		}
	}

	.callout-col {
		@include media-breakpoint-down(md) {
			&:nth-child(n + 3) {
				margin-top: $grid-gutter-width;
			}
		}

		@include media-breakpoint-down(sm) {
			+ .callout-col {
				margin-top: $grid-gutter-width;
			}
		}
	}
}
